<template>
    <el-scrollbar wrap-class="post-to-box">
        <Loading v-if="loading" class="static" />
        <div class="select-top-box" v-if="!loading">
            <div class="line-box">
                <div class="label-line">
                    <label>Select creators</label>
                    <Checkbox :checked="clickAll" :label_text="`All(${props.list.creatorList.length})`" :no_show_label="true"
                        @click="clickAll = !clickAll" />
                </div>
                <div class="input-line">
                    <el-select filterable placeholder="Search name" :disabled="clickAll">
                        <el-option
                            v-for="(item, index) in creatorList && creatorList.every(e => e.pick) ? [] : creatorList"
                            :key="item.id" :label="item.name" v-show="!item.pick" placeholder="Search name"
                            :value="item.name" @click="pickCreator(item, true)" class="creator-item">
                            <img :src="item.avatar ? item.avatar : UserImg" />
                            <span>{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="line-box">
                <div class="label-line">
                    <label>Select groups<tippy content="Select groups"><svg-icon name="question" />
                        </tippy></label>
                </div>
                <div class="input-line">
                    <el-select filterable placeholder="Select" :disabled="clickAll">
                        <el-option v-for="(item, index) in groupList.every(e => e.pick) ? [] : groupList"
                            :key="item.value" :label="item.name" v-show="!item.pick" :value="item.id"
                            @click="pickGroup(item, true)" />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="select-bottom-box"
            v-if="creatorList && creatorList.some(item => item.pick) || groupList.some(item => item.pick)">
            <div class="shadow-box" v-if="clickAll"></div>
            <h3 class="title">Added ({{ count }})</h3>
            <el-collapse v-model="activeNames">
                <el-collapse-item title="Individuals" name="1" v-if="creatorList.some(item => item.pick)">
                    <div class="add-line" v-for="(item, index) in creatorList" :key="index" v-show="item.pick">
                        <img :src="item.avatar ? item.avatar : UserImg" />
                        <div class="name">
                            <p class="bold">{{ item.name }}</p>
                            <p>{{ item.email }}</p>
                        </div>
                        <svg-icon class="stop-svg" name="stop" @click="removeCreator(item)" />
                    </div>
                </el-collapse-item>

                <el-collapse-item title="Groups" name="2" v-if="groupList.some(item => item.pick)">
                    <div class="add-line" v-for="(item, index) in groupList" :key="index" v-show="item.pick">
                        <i class="group-icon"><svg-icon name="users-2" /></i>
                        <div class="name">
                            <p>{{ item.name }}</p>
                        </div>
                        <el-button size="mimi" @click="checkGroupCreators(item)">
                            <svg-icon name="users" />
                            {{ item.creator_count }}
                        </el-button>
                        <svg-icon class="stop-svg" name="stop" @click="removeGroup(item)" />
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="btn-row" v-if="!loading">
            <el-button @click="emit('close')">Cancel</el-button>
            <el-button type="primary" @click="save()">Save</el-button>
        </div>

    </el-scrollbar>

    <el-dialog title="Remove from announcement?" width="400" v-model="removeDialog" :show-close="false"
        @close="removeDialog = false" class="alert-dialog">
        <p>This creator or group will not be able to view this announcement.</p>
        <div class="btn-row">
            <el-button @click="removeDialog = false;">Cancel</el-button>
            <el-button type="primary" @click="handleRemove()">Yes</el-button>
        </div>
    </el-dialog>

    <!-- group list dialog -->
    <el-dialog title="Tag Group" width="600" v-model="groupDialog" @close="groupDialog = false" class="group-dialog">
        <Search @onenter="searchGroupCreator" :placeholder_text="'Search name'"
            :disabled="openGroupListValue.length === 0" v-if="!dialog_loading" />
        <el-scrollbar>
            <Loading class="static" v-if="dialog_loading" />
            <div class="add-line" v-for="(item, index) in openGroupListValue" :key="index">
                <img :src="item.avatar ? item.avatar : UserImg" />
                <div class="name">
                    <p>{{ item.name }}</p>
                    <p>{{ item.email }}</p>
                </div>
            </div>
        </el-scrollbar>
    </el-dialog>
</template>
<script setup>
import emitter from '@/utils/EventBus';
import { ref, onMounted, onUnmounted, computed, getCurrentInstance } from 'vue';
import { ElInput, ElButton, ElLoading, ElSelect, ElOption, ElCollapse, ElCollapseItem, ElScrollbar, ElDialog } from 'element-plus';
import Checkbox from '@/components/ui/Checkbox.vue';
import UserImg from '@/assets/images/user.png';
import Loading from '@/components/ui/Loading.vue';
import Search from '@/components/ui/Search.vue';

const props = defineProps({
    creators: {
        type: Array,
        required: true
    },
    group: {
        type: Array,
        required: true
    },
    click_all: {
        type: Array,
        required: true
    },
    list: {
        type: Object,
        required: true
    }
});
const app = getCurrentInstance();
const ajax = app.appContext.config.globalProperties.$ajax;
const emit = defineEmits(['close']);
const clickAll = ref(true);
const removeDialog = ref(false);
const groupDialog = ref(false);
const openGroupListValue = ref([]);
const openGroupListValueOrgin = ref([]);
const creatorList = ref([]);
const groupList = ref([]);

const handleRemoveValue = ref({
    type: 1,//1:creator,2:group
    item: {}
})

const loading = ref(false);
const dialog_loading = ref(false);
const count = ref(0);
const activeNames = ref(['1', '2']);
//+++++++++++++++++++++++++++++++++++++
// Methods
//+++++++++++++++++++++++++++++++++++++
const pickCreator = (val, handle) => {
    creatorList.value.forEach((item) => {
        if (item.id === val.id) {
            item.pick = handle;
        }
    });
    countUser();
};

const pickGroup = (val, handle) => {
    groupList.value.forEach((item) => {
        if (item.id === val.id) {
            item.pick = handle;
        }
    });
    countUser();
};

const removeCreator = (val) => {
    handleRemoveValue.value = { type: 1, item: val };
    removeDialog.value = true;
};

const removeGroup = (val) => {
    handleRemoveValue.value = { type: 2, item: val };
    removeDialog.value = true;
};

const handleRemove = () => {
    if (handleRemoveValue.value.type === 1) {
        pickCreator(handleRemoveValue.value.item, false);
    } else {
        pickGroup(handleRemoveValue.value.item, false);
    }
    handleRemoveValue.value.item = {};
    removeDialog.value = false;
};
const save = () => {
    let hadOne= creatorList.value.some(item => item.pick);
    let hadGroup= groupList.value.some(item => item.pick);
    if (clickAll.value || (!hadOne && !hadGroup)) {
        emit('close', { type: 'all', data: { creator: [], group: [], count: creatorList.length } });
    } else {
        emit('close', { type: 'pick', data: { creator: creatorList.value.filter(item => item.pick), group: groupList.value.filter(item => item.pick) , count: count.value} });
    }

};

const startFunction = () => {
    clickAll.value = props.click_all;
    count.value= props.click_all? props.list.creatorList.length : props.list.count ?? 0;
    getDropApi();
};

const getDropApi = async () => {

    creatorList.value = props.list.creatorList;
    creatorList.value.forEach(e => {
        e.name = e.name ?? 'No Name People';
        e.pick = props.creators.some(creator => creator.id === e.id);
    })
    groupList.value = props.list.groupList;
    groupList.value.forEach(e => {
        e.name = `${e.tag} (${e.creator_count})`;
        e.pick = props.group.some(gro => gro.id === e.id);
    })

};

//取得群組創作者
const checkGroupCreators = async (item) => {
    openGroupListValue.value = [];
    groupDialog.value = true;
    dialog_loading.value = true;
    const params = { group_id: item.id };
    const response = await ajax.get(`/announcement/get-group-creator-list`, { params });
    const data = response.data.data;
    openGroupListValueOrgin.value = data;
    openGroupListValue.value = data;
    dialog_loading.value = false;
};

const searchGroupCreator = (keyword) => {
    openGroupListValue.value = openGroupListValueOrgin.value.filter(e =>
        e.name && e.name.toLowerCase().includes(keyword.toLowerCase())
    );
};

const countUser = async () => {
    try {
        let params = {
            group_ids: groupList.value.filter(item => item.pick).map(item => item.id),
            creator_ids: creatorList.value.filter(item => item.pick).map(item => item.id),
        };
        const response = await ajax.get(`/announcement/get-unique-creator-count`, { params });
        count.value = response.data.data ? response.data.data : 0;
    } catch (error) {
        count.value = 0;
    }
};
//+++++++++++++++++++++
//mounted
//+++++++++++++++++++++
onMounted(() => {
    startFunction();
});

</script>
<style lang="scss">
.post-to-box {
    position: relative;

    .el-scrollbar {
        max-height: 60vh;
    }

    .el-scrollbar__view {
        max-height: 60vh;
        padding-right: 16px;
    }

    .line-box {
        display: grid;

        .label-line {
            display: flex;
            align-items: center;
            margin: 16px 0;
            gap: 8px;

            label {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 8px;
                font: var(--medium-16);
                color: var(--text-primary);

                .svg-icon {
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                    fill: var(--text-default);
                }
            }

            .ui-checkbox {
                margin: 0;
                font: var(--medium-14);
                color: var(--text-primary);
            }
        }

        .input-line {
            display: grid;

            .el-select {
                width: 100%;
            }

        }
    }

    .select-top-box {
        border-top: solid 1px var(--border);
    }

    .select-bottom-box {
        border-top: solid 1px var(--border);
        margin-top: 32px;
        padding: 16px 0;
        position: relative;

        .shadow-box {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--black-100);
            opacity: .3;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        h3 {
            font: var(--medium-16);
            color: var(--text-primary);
        }

        .el-collapse {
            border: none;

            .el-collapse-item__header {
                font: var(--font);
                font-size: 12px;
                color: var(--text-primary);
            }


            .el-collapse-item__content {
                display: grid;
                gap: 8px;
            }
        }
    }

    .btn-row {
        position: sticky;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 8px 0 4px;
        background-color: var(--white);
        z-index: 2;
    }

}

.add-line {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: contain;
        border: solid 1px var(--border);
    }

    .group-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: solid 1px var(--border);
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-icon {
            width: 16px;
            height: 16px;
            fill: var(--Text-default);
        }
    }

    .name {
        flex: 1;
        
        p {
            &:first-child {
                font: var(--medium-12);
                color: var(--text-primary);
            }

            &:last-child {
                font-size: 12px;
                color: var(--text-secondary);
            }
        }
    }

    .el-button--mimi span {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .svg-icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    .stop-svg {
        fill: var(--red);
    }

}

.group-dialog {
    .el-scrollbar__view {
        display: grid;
        gap: 8px;
    }

    .ui-search-input {
        .input {
            max-width: unset;
        }

        margin: 0 0 16px 0;
    }
}

.el-select-dropdown {
    .creator-item {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: contain;
            border: solid 1px var(--border);
        }
    }
}
</style>