<template>
  <div class="editor-container">
    <Loading v-if="loading" />
    <Editor id="description" :apiKey="apiKey" v-model="content" :init="editorConfig" />
    <!-- <div class="character-counter">{{ charCount }}/100000</div> -->
  </div>
</template>

<script setup>
import { ref, onMounted, watch, getCurrentInstance } from "vue";
import { default as Editor } from "@tinymce/tinymce-vue";
import { useNotification } from '@/components/ui/notification';
import Loading from "@/components/ui/Loading.vue";
//import { useGlobalStore } from '@/stores/GlobalStore'

//const store = useGlobalStore();
const app = getCurrentInstance();
const ajax = app.appContext.config.globalProperties.$ajax;
const { openNotBottom } = useNotification();
const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const content = ref(props.modelValue || "");
const apiKey = ref('c1alfret2hep8ly0ljv4bzkt8u257yn7p2w87xmdes22tsz4');
const loading = ref(false);

// TinyMCE 
const editorConfig = {
  apiKey: apiKey.value,
  menubar: false,
  content_style: "[data-mce-placeholder] { color: #bdbdbd !important;font-size: 14px; font-family: sans-serif;}",
  plugins: "tinydrive link image media advlist autolink charmap preview fontsize",
  //| code | image media
  toolbar: "undo redo | styles | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link image media",
  fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt;",
  block_formats: "Heading 1=h1; Heading 2=h2; Heading 3=h3;Paragraph=p; ",
  // 只保留指定的標題和內聯樣式
  style_formats: [
    // 標題
    {
      title: 'Headings', items: [
        { title: 'Heading 1', format: 'h1' },
        { title: 'Heading 2', format: 'h2' },
        { title: 'Heading 3', format: 'h3' },
        { title: 'Heading 4', format: 'h4' },
        { title: 'Heading 5', format: 'h5' },
        { title: 'Heading 6', format: 'h6' }
      ]
    },
    // 內聯樣式
    {
      title: 'Inline', items: [
        { title: 'Bold', format: 'bold' },
        { title: 'Italic', format: 'italic' },
        { title: 'Underline', format: 'underline' },
        { title: 'Strikethrough', format: 'strikethrough' }
      ]
    }
  ],
  placeholder: "Lorem ipsum dolor sit amet consectetur.",
  statusbar: false, // Hide the bottom status bar
  toolbar_location: "top", // Toolbar position
  image_title: true, // Enable image title
  automatic_uploads: false, // Disable automatic image uploads
  notifications: false, // Disable notifications
  file_picker_types: "image media", // Support images and videos
  // Image settings
  image_advtab: false,  // Disable advanced options tab
  image_uploadtab: false, // Disable upload tab
  image_dimensions: true, // Enable image dimension settings
  image_class_list: false, // Disable style class options
  media_dimensions: true, // Enable media dimension settings
  media_live_embeds: true, // Allow media preview
  media_alt_source: true, // Enable alternative source
  media_poster: true, // Enable preview image settings
  media_filter_html: false, // Disable HTML filtering
  extended_valid_elements: "video[*],source[*]",
  file_picker_callback: async (callback, value, meta) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", meta.filetype === "image" ? "image/*" : "video/*");

    input.onchange = async () => {
      const file = input.files[0];
      try {
        const blob = await loadImage(file);
        // 關鍵修改：直接傳入 blob URL，而不是完整的 HTML 字符串
        if (meta.filetype === "video") {
          callback(blob.src, {
            title: file.name
          });
        } else {
          callback(blob.src, {
            title: file.name,
            alt: file.name
          });
        }

      } catch (error) {
        openNotBottom(error, 'error');
      }
    };
    input.click();
  },
  content_style: `
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    ul,
    ol,
    table {
      margin: 16px 0;
      color:#333;
      font-family: 'Franklin Gothic Medium', sans-serif;
      font-weight: 400;
    }

    strong {
      font-weight: 700;
    }

    h1 { font-family: 'Franklin Gothic Medium', sans-serif; font-size:26px; font-weight:400; }
    h2 { font-family: 'Franklin Gothic Medium', sans-serif; font-size:24px; font-weight:400; }
    h3 { font-family: 'Franklin Gothic Medium', sans-serif; font-size:22px; font-weight:400; }
    h4 { font-family: 'Franklin Gothic Medium', sans-serif; font-size:16px; font-weight:400; }
    h5 { font-family: 'Franklin Gothic Medium', sans-serif; font-size:12px; font-weight:400; }
    h6 { font-family: 'Franklin Gothic Medium', sans-serif; font-size:10px; font-weight:400; }

    ul, ol {
      padding-left: 24px;
      margin-bottom: 16px;
    }

    ul li,
    ol li {
      margin-bottom: 8px;
    }

    ul li { list-style: disc; }
    ol li { list-style: decimal; }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 16px;
      border: 1px solid #F2F2F2;
    }

    th, td {
      border: 1px solid #F2F2F2;
      padding: 8px;
    }

    th {
      background-color: #F6F8FC;
    }

    hr {
      border: none;
      border-top: 1px solid #E0E0E0;
    }

    em {
      font-style: italic;
    }

    a {
      color: #007BFF;
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }

    .video-wrap {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      margin-bottom: 16px;
    }

    .video-wrap video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,
  drag_drop: true, // 啟用拖放
  setup: (editor) => {
    // 拖放檔案
    editor.on("drop", async (e) => {
      // 如果是從外部拖入新檔案
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith("video/")) {
          e.preventDefault();
          try {
            const blob = await loadImage(file);
            const insertedContent = `<video controls src="${blob.src}"></video>`;
            editor.insertContent(insertedContent);
          } catch (error) {
            openNotBottom(error, 'error');
          }
        }
        else {
          e.preventDefault();
          try {
            const blob = await loadImage(file);
            const insertedContent = `<img src="${blob.src}" alt="${file.name}" title="${file.name}" />`;
            editor.insertContent(insertedContent);
          } catch (error) {
            openNotBottom(error, 'error')
          }
        }
      }
      // 如果是編輯器內部的拖曳，不做處理讓 TinyMCE 自己處理
    });

    const updateContent = () => {
      //console.log('editor.getContent()', editor.getContent());
      emit("update:modelValue", editor.getContent());
    };
    editor.on("keyup", updateContent);
    editor.on("change", updateContent);

    // 關閉特定類型的通知
    editor.on('BeforeOpenNotification', (e) => {
      e.preventDefault();
      return false;
    });
  }
};

//base64真的太大，做個暫緩
const loadImage = (file) => {
  return new Promise((resolve, reject) => {
    // 檢查檔案大小
    const isImage = file.type.startsWith('image/');
    const isVideo = file.type.startsWith('video/');

    // 轉換 MB 到 bytes (1MB = 1024 * 1024 bytes)
    const IMAGE_SIZE_LIMIT = 10 * 1024 * 1024; // 10MB
    const VIDEO_SIZE_LIMIT = 50 * 1024 * 1024; // 50MB

    if (isImage && file.size > IMAGE_SIZE_LIMIT) {
      reject('Uploaded images must not exceed 10MB.');
      return;
    }

    if (isVideo && file.size > VIDEO_SIZE_LIMIT) {
      reject('Uploaded images must not exceed 50MB.');
      return;
    }

    const uploadMedia = async () => {
      let formData = new FormData();
      formData.append('media', file);
      loading.value = true;
      try {
        const res = await ajax.post('announcement/upload-announcement-media', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        // 只返回 src
        resolve({
          src: res.data.data
        });
        loading.value = false;
      } catch (error) {
        openNotBottom(`Upload media failed.`, 'error');
        reject(`Upload media failed.`);
        loading.value = false;
      }
    };

    uploadMedia();
  });
};

//++++++++++++++++++
//mounted
//++++++++++++++++++
onMounted(() => {
  content.value = props.modelValue;
});
</script>

<style lang="scss">
@mixin flexCenter() {
  display: flex;
  align-items: center;
}

.editor-container {
  width: 100%;
  margin: auto;
  position: relative;
  font-family: var(--font);

  label {
    @include flexCenter;

    &::after {
      content: '*';
      color: var(--Red-30);
      margin-left: 4px;
      font: var(--font-med-14);
    }
  }

  .character-counter {
    text-align: right;
    font-size: 12px;
    color: var(--Text-default);
    position: absolute;
    bottom: 52px;
    z-index: 2;
    right: 8px;
  }
}

//tinymce custom style
.tox-dialog {
  padding: 8px;
  font-family: var(--font-med);

  .tox-dialog__header {
    h1 {
      font: var(--font-med-24);
    }
  }

  .tox-dialog__body-content {
    padding-bottom: 0;

    .tox-form {
      display: block;

      >.tox-form__group {
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }

        .tox-label {
          font: var(--font-med-16);
          color: var(--Text-primary);
          margin-bottom: 8px;
        }

        input:focus {
          border: 1px solid var(--dark-blue);
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.tox {
  .tox-button[aria-label="Save"] {
    background-color: var(--PB-100) !important;
    border-color: var(--PB-100) !important;
  }

  .tox-button {
    font: var(--font-med-14);

    &::before {
      display: none;
    }
  }
}
</style>
