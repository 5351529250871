<template>
  <el-drawer
    v-model="visible"
    :with-header="false"
    :destroy-on-close="true"
    class="announcements-drawer"
  >
    <div class="announcements-wrap">
      <i class="close" @click="close">
        <svg viewBox="0 0 10 16">
          <path d="M9.93957 1.88L8.05957 0L0.0595703 8L8.05957 16L9.93957 14.12L3.8329 8L9.93957 1.88Z" />
        </svg>
      </i>
      <header>
        <el-image v-if="props.merchant.logo" :src="props.merchant.logo" fit="contain" />
        <h1>{{ props.merchant.name }}</h1>
      </header>
      <ui-loading class="static" v-if="loading" />
      <ul class="announcements-list" v-else-if="list.length > 0">
        <li class="announcement-item" v-for="item in list" :key="item.id" @click="emit('open-announcement', item.id)">
          <el-image v-if="item.thumbnail_url" :src="item.thumbnail_url" fit="cover" />
          <div class="announcement-content">
            <h3>{{ item.title }}</h3>
            <p>{{ desc(item.description) }}</p>
          </div>
          <time>{{ moment(item.updated_at).format('MMM DD, YYYY') }}</time>
          <i class="more">
            <svg viewBox="0 0 6 8">
              <path d="M1.47027 0L0.530273 0.94L3.58361 4L0.530273 7.06L1.47027 8L5.47027 4L1.47027 0Z" f/>
            </svg>
          </i>
        </li>
        <li class="load-more" v-if="list.length !== 0 && !isEnd">
          <el-button @click="getAnnouncements(true)" v-loading="loadingMore">Load more</el-button>
        </li>
        <li class="no-announcements" v-else-if="isEnd && list.length !== 0">
          <p>No more announcements</p>
        </li>
      </ul>
      <div class="no-announcements" v-else-if="isEnd && list.length === 0">
        <p>No announcements</p>
      </div>
    </div>
  </el-drawer>

</template>

<script setup>
  import { ref } from 'vue';
  import { ElDrawer } from 'element-plus';
  import moment from 'moment';

  const props = defineProps(
    {
      merchant: {
        type: Object,
        required: true
      },
      ajax: {
        type: Function,
        required: true
      },
      store: {
        type: Object,
        default: null,
      }
    }
  );

  const emit = defineEmits(['open-announcement']);
  const visible = ref(false);
  const loading = ref(true);
  const loadingMore = ref(false);
  const list = ref([]);
  const isEnd = ref(false);
  const page = ref(1);

  const getAnnouncements = async (is_next = false) => {

    if (is_next) {
      page.value += 1;
      loadingMore.value = true;
    } else {
      loading.value = true;
      page.value = 1;
      list.value = [];
    }

    let merchant_account_id= props.merchant.id ? `merchant_account_id=${props.merchant.id}&` :'';
    const { data } = await props.ajax({
      url: `/chatroom/get-announcement-list?${merchant_account_id}page=${page.value}`,
      method: 'get'
    });

    data.data.data.forEach((item) => {
      list.value.push(item);
    });

    isEnd.value = data.data.next_page_url === null;
    loading.value = false;
    loadingMore.value = false;
  }

  const desc = (str) => {
    // remove all html tags
    const regex = /(<([^>]+)>)/ig;
    const result = str.replace(regex, '');
    return result;
  }

  const open = () => {
    visible.value = true;
    getAnnouncements();
  
  }

  const close = () => {
    visible.value = false;
    if(props.store){
      props.store.open_announcement = false;
    }

    setTimeout(() => {
      page.value = 1;
      list.value = [];
      isEnd.value = false;
    }, 600);
  }
  

  defineExpose({
    open,
    close
  });

</script>

<style lang="scss">
  .announcements-drawer {
    width: calc(100% - 200px) !important;

    @media screen and (max-width: $mobile_size) {
      width: 100% !important;
    }
  }

  .announcements-wrap {
    padding: 16px 0 0;
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    box-sizing: border-box;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--radius);
      height: 32px;
      width: 32px;
      cursor: pointer;
      margin: 0 24px 16px;

      @media screen and (max-width: $mobile_size) {
        margin: 0 16px 16px;
        
      }

      svg {
        width: 16px;
        height: 16px;
        fill: var(--Black-90);
      }

      &:hover {
        background-color: var(--Neu-20);
      }
    }

    header {
      display: flex;
      align-items: center;
      padding: 0 24px;

      .#{$prefix}-image {
        width: 64px;
        height: 64px;
        border-radius: var(--radius);
        // box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
        display: block;
        overflow: hidden;
        margin-right: 12px;
      }

      h1 {
        font: var(--medium-24);
        color: var(--Text-primary);
      }

      @media screen and (max-width: $mobile_size) {
        padding: 0 16px;

        .#{$prefix}-image {
          width: 48px;
          height: 48px;
        }

        h1 {
          font: var(--medium-20);
        }
        
      }
    }
    
    .announcements-list {
      overflow: auto;
      padding: 24px;
      margin: 0 -8px;
      mask: linear-gradient(0deg, transparent  0%, #fff 24px, #fff calc(100% - 24px), transparent 100%);

      @media screen and (max-width: $mobile_size) {
        padding: 16px;
        margin: 0;
      }

      .announcement-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 8px;
        cursor: pointer;
        border-radius: var(--radius);
        transition: all 0.2s ease;

        &:hover {
          background-color: var(--Neu-20);
        }


        .#{$prefix}-image {
          width: 48px;
          height: 48px;
          border-radius: var(--radius);
          display: block;
          overflow: hidden;
          margin-right: 10px;
        }

        .announcement-content {
          flex: 1;

          h3 {
            font: var(--medium-16);
            margin-bottom: 4px;
          }

          p {
            font: var(--medium-14);
            color: var(--Text-secondary);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        time {
          font: var(--medium-12);
          color: var(--Text-default);
          padding: 0 16px;
          white-space: nowrap;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 20px;
          min-width: 20px;
          cursor: pointer;

          svg {
            width: 8px;
            height: 8px;
            fill: var(--Black-90);
          }


        }

        @media screen and (max-width: $mobile_size) {          
          .ui-image,
          time {
            display: none;
          }
        }
      }

      .load-more {
        text-align: center;
        padding: 16px 0;
      }
    }

    .no-announcements {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0;
      color: var(--Text-secondary);
    }
  }
</style>