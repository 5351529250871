<template>
    <ModalSide class="Announcements-modal" ref="modal" @close="handleClose" :handle_close="true">
        <Loading v-if="loading" class="absolute" />

        <div class="edit-announcement" v-if="!controls.is_view">
            <header>
                <div class="header-left">
                    <svg-icon name="close" @click="handleClose()" />
                    <h2>{{ title }}</h2>
                </div>
                <div class="header-right">
                    <el-button @click="controls.is_view = true" text v-if="!controls.is_new">
                        <svg-icon name="preview" />Preview
                    </el-button>
                    <el-button @click="deleteDialog = true" type="danger" text
                        v-if="!controls.is_new">Delete</el-button>
                    <!-- <el-button @click="save()" :disabled="!ruleForm.title">Save</el-button> -->
                    <el-button v-if="controls.type == 1 || controls.is_new" type="primary" @click="Publish(true)"
                        :disabled="!canPublish">Publish</el-button>
                    <el-button v-if="controls.type == 2 && !controls.is_new" type="primary" @click="Publish(false)"
                        :disabled="!checkValue">Unpublish</el-button>
                </div>
            </header>
            <Scrollbar>
                <div class="content-left">
                    <div class="content-send-opt">
                        <div class="line" style="margin-bottom: 16px;">
                            <label for="title">Title</label>
                            <el-input v-model="ruleForm.title" placeholder="Enter title" maxlength="1000" />
                        </div>
                        <div class="flex-line">
                            <label for="sendOptions">Description</label>
                            <EditTinymceBox v-model="ruleForm.desc" />
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <h3>Settings</h3>
                    <div class="content-head">
                        <div class="content-head-left">
                            <p> Thumbnail (optional)</p>
                            <figure class="avatar" @click="is_show_cropper = true">
                                <img v-if="ruleForm.img.src" :src="ruleForm.img.src" alt="over image" />
                                <div class="img-box-empty" v-else>
                                    <div class="img-box-empty-content">
                                        <svg-icon name="add_box" />
                                        <p>Add new</p>
                                    </div>
                                </div>
                            </figure>
                        </div>
                        <el-button class="el-button--w100" v-if="ruleForm.img.src"
                            @click="ruleForm.img.src = ''; ruleForm.img.data = null; handle_clear_key += 1;">Clear
                            image</el-button>
                    </div>

                    <div class="content">
                        <div class="flex-line">
                            <label for="sendOptions">Send options</label>
                            <div class="opt">
                                <el-button :class="['el-button--w100', ruleForm.send_time_opt === 1 && 'click-btn']"
                                    @click="ruleForm.send_time_opt = 1;">Send
                                    now<svg-icon name="yes-2" v-if="ruleForm.send_time_opt === 1" /></el-button>
                                <el-button :class="['el-button--w100', ruleForm.send_time_opt === 2 && 'click-btn']"
                                    @click="ruleForm.send_time_opt = 2;">Send
                                    later<svg-icon name="yes-2" v-if="ruleForm.send_time_opt === 2" /></el-button>
                            </div>
                        </div>
                        <DateTimePicker v-if="ruleForm.send_time_opt === 2" :key="0" :date="ruleForm.send_date"
                            :time="ruleForm.send_time" :datetime="ruleForm.send_datetime" :format="'DD/MM/YYYY'"
                            @update-date="calendarChange" @update-time="timeChange" :min_date="new Date()" />
                    </div>

                    <div class="content-content">


                        <label for="post_to">Send to</label>
                        <div class="post-to">
                            <div class="post-to-fake-input">
                                <span v-for="(item, index) in ruleForm.post_to.data" :key="index">
                                    {{ item.tag ? `${item.tag} (${item.creator_count})` : item.name }}
                                </span>
                            </div>
                            <el-button @click="is_show_postto = true">Edit</el-button>
                        </div>

                        <label for="post_to">Notifications</label>
                        <div class="desc">Notify creators or groups when this announcement is sent.</div>
                        <Checkbox v-if="brand.is_enable_chatroom"
                            :label_text="`Send 1:1 message to applicable creators.  They will be able to respond individually.`"
                            :checked="ruleForm.send_chatroom" :no_show_label="true"
                            @click="ruleForm.send_chatroom = !ruleForm.send_chatroom" />
                        <Checkbox :label_text="`Send email notification`" :checked="ruleForm.send_email"
                            :no_show_label="true" @click="ruleForm.send_email = !ruleForm.send_email" />
                    </div>

                </div>


                <div class="sticky-btn">
                    <el-button type="primary" size="large" class="el-button--w100" :disabled="!canSave"
                        @click="save">Save</el-button>
                </div>
            </Scrollbar>
        </div>

        <!-- 預覽(編輯期間沒存檔的時候，要用原本的資料預覽(因為複製是複製存檔過的)) -->
        <div class="view-announcement" v-else>
            <header>
                <div class="header-left">
                    <svg-icon name="close" @click="handleClose()" />
                </div>
                <div class="header-right">
                    <el-button @click="Duplicate()" v-if="!controls.is_new">Duplicate to draft</el-button>
                    <el-button @click="deleteDialog = true" type="danger" text>Delete</el-button>
                    <el-button @click="controls.is_view = false"
                        v-if="controls.type != 3 || (controls.type == 3 && controls.is_new)">Edit</el-button>
                    <el-button type="primary" @click="Publish(true)" :disabled="!canPublish"
                        v-if="controls.type == 1 || controls.is_new">Publish</el-button>
                    <el-button type="primary" @click="Publish(false)" :disabled="!checkValue"
                        v-if="controls.type == 2 && !controls.is_new">Unpublish</el-button>
                </div>
            </header>

            <Scrollbar>
                <h3>{{ copyRulForm.title }}</h3>
                <div class="detail-box">
                    <div class="brand-box">
                        <img :src="brand.brand_logo" alr="brand_name">
                        <span>{{ brand.merchant_display_name }}</span>
                    </div>
                    <div class="detail-line">
                        <div class="line">To: <span>{{ copyRulForm.post_to_count }} creators</span></div>
                        <div class="line" v-if="controls.type == 3">Published: <span>{{ copyRulForm.publish_date
                                }}</span>
                        </div>
                        <div class="line">Last updated: <span>{{ copyRulForm.last_updated }}</span></div>
                        <div class="line">Created by: <span>{{ copyRulForm.created_by_user_name }}</span></div>
                        <div class="line" v-if="controls.type == 2">Scheduled: <span>{{ copyRulForm.publish_date
                                }}</span>
                        </div>
                        <div class="line" v-if="controls.type == 3">Views: <span>{{ copyRulForm.views }}</span></div>
                        <div class="line">
                            <StatusText :status="copyRulForm.status.status"
                                :outside_status_text="copyRulForm.status.text" />
                        </div>
                    </div>
                </div>
                <div class="view-content" v-html="copyRulForm.desc"></div>
            </Scrollbar>
        </div>

        <!-- handle image -->
        <el-dialog v-model="is_show_cropper" title="Cover image" width="1000" :key="handle_clear_key">
            <UploadImage @saveAvatar="saveAvatar" :aspectRatio="1" :maxImage="5"/>
        </el-dialog>

        <!-- handle Send to -->
        <el-dialog v-model="is_show_postto" title="Send to" width="800" @close="is_show_postto = false">
            <PostTo @close="closePostTo" :creators="ruleForm.recipient_creators" :group="ruleForm.recipient_groups"
                :click_all="ruleForm.click_all" :key="is_show_postto"
                :list="{ creatorList: creatorList, groupList: groupList, count: ruleForm.post_to_count }" />
        </el-dialog>

        <!-- Unsaved changes dialog -->
        <el-dialog title="Unsaved changes" width="400" v-model="unSaveDialog" :show-close="false"
            @close="unSaveDialog = false" class="alert-dialog">

            <p>Any unsaved changes will be permanently removed.</p>
            <p class="alert" v-if="!canSave">The cover image, title, and description are required fields in the form
                when it is set to "Scheduled." Otherwise, saving is not allowed.</p>

            <div class="btn-row">
                <el-button @click="modal.closeAgain()">Discard</el-button>
                <el-button type="primary" @click="save('close')" :disabled="!canSave">Save & exit</el-button>
            </div>
        </el-dialog>

        <!-- Delete dialog -->
        <el-dialog :title="`Delete ${controls.type == 1 ? 'draft?' : 'announcement?'}`" width="400" v-model="deleteDialog"
            :show-close="false" @close="deleteDialog = false" class="alert-dialog">
            <p>This action cannot be undone.</p>
            <div class="btn-row">
                <el-button @click="deleteDialog = false;">Cancel</el-button>
                <el-button type="primary" @click="deleteAnn">Delete</el-button>
            </div>
        </el-dialog>
    </ModalSide>

</template>
<script setup>
import ModalSide from '@/components/ui/ModalSide.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import emitter from '@/utils/EventBus';
import { ref, onMounted, onUnmounted, computed, getCurrentInstance } from 'vue';
import { ElInput, ElSelect, ElButton, ElLoading, ElDialog } from 'element-plus';
import Checkbox from '@/components/ui/Checkbox.vue';
import EditTinymceBox from '@/components/ui/EditTinymceBox.vue';
import DateTimePicker from '@/components/ui/DateTimePicker.vue';
import UploadImage from '@/components/ui/UploadImage.vue';
import moment from 'moment';
import PostTo from '@/components/messaging/Announcements/PostTo.vue';
import { useNotification } from '@/components/ui/notification';
import StatusText from '@/components/ui/StatusText.vue';
import { useStore } from 'vuex';
import Loading from '@/components/ui/Loading.vue';


const app = getCurrentInstance();
const ajax = app.appContext.config.globalProperties.$ajax;
const { openNotBottom } = useNotification();
const store = useStore();
const modal = ref(false);
const title = ref('New Announcement');
const controls = ref({
    //draft: 1,scheduled: 2,published: 3
    is_view: true,
    type: 1,
    is_new: true,//建立和編輯的API不一樣，看這值判斷
});
const is_show_cropper = ref(false);
const is_show_postto = ref(false);
const ruleForm = ref({
    img: {
        src: null,
        data: null
    },
    title: '',
    post_to: { type: 'all', data: [{ name: 'All' }] },
    send_chatroom: false,
    send_email: false,
    send_time_opt: 1,//now=>1,later=>2
    send_date: null,
    send_time: null,
    send_datetime: null,
    desc: ''
});
const copyRulForm = ref(null);//檢查資料是否有更動過
const creatorList = ref([]);
const groupList = ref([]);
const loading = ref(false);
const unSaveDialog = ref(false);
const deleteDialog = ref(false);
const handle_clear_key = ref(0);

//必填沒填不可以發布
const canPublish = computed(() => {
    let val = ruleForm.value;
    if (val.send_time_opt === 2 && !val.send_datetime) {
        return false;
    } else {
        return val.title && val.post_to.data && val.desc;
    }
});

const canSave = computed(() => {
    //要注意如果已經再scheduled或published狀態下，存檔要把必填都填起來
    return ruleForm.value.status_text !== 'scheduled'
        ? checkValue.value
        : canPublish.value
});

const checkValue = computed(() => {
    return ruleForm.value.title || ruleForm.value.desc || ruleForm.value.img.src;
});

const brand = computed(() => {
    return store.state.user_data.merchant_account;
});
//+++++++++++++++++++++++++++++++++++++
// Methods
//+++++++++++++++++++++++++++++++++++++
const openEditing = (val) => {
    title.value = val.title;
    resetModal();
    controls.value.is_view = val.is_view;
    controls.value.type = val.type;
    controls.value.is_new = !val.data;
    handleData(val.data);
    copyRulForm.value = JSON.parse(JSON.stringify(ruleForm.value));
    getDropApi();
    modal.value.open();
};

const handleData = (data) => {
    ruleForm.value.id = data && data.id ? data.id : null;
    ruleForm.value.title = data && data.title ? data.title : '';
    ruleForm.value.post_to_count = data && data.recipient_creator_count ? data.recipient_creator_count : 0;
    ruleForm.value.publish_date = data && data.schedule_send_datetime ? moment(data.schedule_send_datetime).format('MMM DD, YYYY HH:mm:ss') : null;
    ruleForm.value.last_updated = data && data.updated_at ? moment(data.updated_at).format('MMM DD, YYYY HH:mm:ss') : null;
    ruleForm.value.created_by_user_name = data && data.created_by_user_name ? data.created_by_user_name : '';
    ruleForm.value.views = data && data.views ? data.views : '---';
    ruleForm.value.status_text = data && data.status_name ? data.status_name : '';
    ruleForm.value.status = data ? handleStatus(data.status_name) : {
        text: 'Draft',
        status: null
    };
    ruleForm.value.desc = data && data.description ? data.description : '';
    ruleForm.value.img = {
        src: data && data.thumbnail_url ? data.thumbnail_url : null,
        data: null,
    };
    if ((data && data.select_all_creator) || !data) {
        ruleForm.value.post_to = { type: 'all', data: [{ name: 'All' }] };
    } else {
        ruleForm.value.post_to = { type: 'pick', data: [...data.recipient_creators, ...data.recipient_groups] };
    }
    ruleForm.value.click_all = (data && data.select_all_creator) || !data ? true : false;
    ruleForm.value.recipient_creators = data && data.recipient_creators ? data.recipient_creators : [];
    ruleForm.value.recipient_groups = data && data.recipient_groups ? data.recipient_groups : [];
    ruleForm.value.send_chatroom = data && data.send_chatroom ? true : false;
    ruleForm.value.send_email = data && data.send_email ? true : false;
    ruleForm.value.send_time_opt = data && data.send_option === 'later' ? 2 : 1;
    ruleForm.value.send_date = data && data.schedule_send_datetime && data.send_option === 'later' ? moment(data.schedule_send_datetime).format('YYYY-MM-DD') : null;
    ruleForm.value.send_time = data && data.schedule_send_datetime && data.send_option === 'later' ? moment(data.schedule_send_datetime).format('HH:mm:ss') : null;
    ruleForm.value.send_datetime = data && data.schedule_send_datetime && data.send_option === 'later' ? moment(data.schedule_send_datetime).format('YYYY-MM-DD HH:mm:ss') : null;
    console.log('ruleForm.value', ruleForm.value)
};

const handleStatus = (status) => {
    let val = {
        text: '',
        status: null
    }
    switch (status) {
        case 'active':
            val.text = 'Published';
            val.status = 20;
            break;
        case 'draft':
            val.text = 'Draft';
            val.status = null;
            break;
        case 'scheduled':
            val.text = 'Scheduled';
            val.status = 30;
            break;
    }
    return val;
}

const resetModal = () => {
    deleteDialog.value = false;
    unSaveDialog.value = false;
};

const saveAvatar = (data) => {
    ruleForm.value.img = data;
    is_show_cropper.value = false;
};

const calendarChange = (val) => {
    ruleForm.value.send_date = moment(val.val, 'DD/MM/YYYY').format('YYYY-MM-DD');
    handleDateTime();
};

const handlForApiData = (type) => {
    let status = '';

    if (ruleForm.value.status_text && type === 'save') {
        status = ruleForm.value.status_text;
    } else if (type === 'publish') {
        status = 'active';
    } else if (type === 'unPublish') {
        status = 'draft';
    } else {
        status = 'draft';
    }

    let datetime = ruleForm.value.send_time_opt === 2 ? ruleForm.value.send_datetime : status !== 'draft' ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss') : null;
    let params = {
        creator_ids: ruleForm.value.recipient_creators.map(item => item.id),
        group_ids: ruleForm.value.recipient_groups.map(item => item.id),
        select_all_creator: ruleForm.value.click_all, // boolean: Optional
        title: ruleForm.value.title ?? '', // string
        description: ruleForm.value.desc, // string
        send_option: ruleForm.value.send_time_opt === 1 ? 'now' : 'later', // string: "now/later"
        status: status, // string: "active/draft"
        send_email: ruleForm.value.send_email, // boolean
        send_chatroom: ruleForm.value.send_chatroom, // boolean
    };
    if (ruleForm.value.send_time_opt === 2 || status === 'active') {
        params.schedule_send_datetime = datetime; //"2025-01-01 11:23:02"
    }
    if (ruleForm.value.img.data) {
        params.announcement_cover = ruleForm.value.img.data;
    } else if (ruleForm.value.img.src) {
        params.thumbnail_url = ruleForm.value.img.src;
    }
    if (!controls.value.is_new) params.id = ruleForm.value.id;

    let formData = new FormData();


    for (const key in params) {
        if (Array.isArray(params[key])) {
            params[key].forEach(value => {
                formData.append(`${key}[]`, value);
            });
        } else {
            formData.append(key, params[key]);
        }
    }

    return formData;
};

const timeChange = (val) => {
    ruleForm.value.send_time = val.val;
    handleDateTime();
};

const handleDateTime = () => {
    if (ruleForm.value.send_date !== 'Invalid date') {
        if (ruleForm.value.send_time !== 'Invalid date' && ruleForm.value.send_time) {
            ruleForm.value.send_datetime = moment(`${ruleForm.value.send_date} ${ruleForm.value.send_time}`).format('YYYY-MM-DD HH:mm:ss');
        } else {
            ruleForm.value.send_time = '00:00:00';
            ruleForm.value.send_datetime = moment(`${ruleForm.value.send_date} ${ruleForm.value.send_time}`).format('YYYY-MM-DD HH:mm:ss');
        }

    } else {
        ruleForm.value.send_datetime = '';
    }
};

const save = async (handle) => {

    try {
        // 建立和存檔不一樣的API
        loading.value = true;
        let api = controls.value.is_new ? '/announcement/create-announcement' : '/announcement/update-announcement';
        let formData = handlForApiData('save');
        const response = await ajax.post(api, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (controls.value.is_new) {
            openNotBottom(`Announcement saved to draft.`, 'success');
            ruleForm.value.id = response.data.data;
            controls.value.is_new = false;
        } else {
            openNotBottom(`Change saved.`, 'success');
        };
        copyRulForm.value = JSON.parse(JSON.stringify(ruleForm.value));

        modal.value.closeAgain();
        loading.value = false;
        if (!loading.value) {
            emitter.emit('call-table-announcement');
        }
    } catch (error) {
        openNotBottom(`Save failed.`, 'error');
        loading.value = false;
    }

};

const Publish = async (publish) => {
    try {
        loading.value = true;
        let api = controls.value.is_new ? '/announcement/create-announcement' : '/announcement/update-announcement';
        let text = publish ? 'publish' : 'unPublish';
        let formData = handlForApiData(text);
        const response = await ajax.post(api, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        // ruleForm.value.id = response.data.data;
        // controls.value.is_new = false;
        // controls.value.type = publish ? 1 : 2;
        // ruleForm.value.status = handleStatus(publish ? ruleForm.value.send_time_opt === 1 ? 'active' : 'scheduled' : 'draft');
        // ruleForm.value.status_text = publish ? ruleForm.value.send_time_opt === 1 ? 'active' : 'scheduled' : 'draft';
        // copyRulForm.value = JSON.parse(JSON.stringify(ruleForm.value));
        modal.value.closeAgain();//直接關閉不需要再塞資料，重開會重新處理
        openNotBottom(`Announcement ${publish ? `${text} & scheduled` : `${text}`}`, 'success');
        loading.value = false;
        if (!loading.value) {
            emitter.emit('call-table-announcement');
        }
    } catch (error) {
        openNotBottom(`${publish ? 'Published' : 'UnPublished'} failed.`, 'error');
        loading.value = false;
    }
};

const deleteAnn = async () => {
    loading.value = true;
    try {
        await ajax.post('/announcement/delete-announcement', { id: ruleForm.value.id });
        openNotBottom(`Announcement deleted.`, 'success');
        loading.value = false;
        if (!loading.value) {
            emitter.emit('call-table-announcement');
        }
        modal.value.closeAgain();
    } catch (error) {
        openNotBottom(`Announcement deleted failed.`, 'error');
    }


};

const Duplicate = async () => {
    loading.value = true;
    try {
        await ajax.post('/announcement/clone-announcement', { id: ruleForm.value.id });
        openNotBottom(`Announcement duplicate.`, 'success');
        loading.value = false;
        if (!loading.value) {
            emitter.emit('call-table-announcement');
        }
        //modal.value.closeAgain();
    } catch (error) {
        loading.value = false;
        openNotBottom(`Announcement duplicate failed.`, 'error');
    }
}

const handleClose = () => {
    if (JSON.stringify(copyRulForm.value) === JSON.stringify(ruleForm.value)) {
        modal.value.closeAgain();
    } else {
        if (canSave.value) {
            unSaveDialog.value = true;
        } else {
            modal.value.closeAgain();
        }
    }

};

const closePostTo = (val) => {
    is_show_postto.value = false;
    if (val) {
        if (val.type === 'all') {
            ruleForm.value.post_to = { type: 'all', data: [{ name: 'All' }] };
            ruleForm.value.recipient_creators = [];
            ruleForm.value.recipient_groups = [];
            ruleForm.value.post_to_count = val.data.count;
            ruleForm.value.click_all = true;
        } else {
            ruleForm.value.post_to = { type: 'pick', data: [...val.data.creator, ...val.data.group] };
            ruleForm.value.recipient_creators = val.data.creator;
            ruleForm.value.recipient_groups = val.data.group;
            ruleForm.value.post_to_count = val.data.count;
            ruleForm.value.click_all = false;
        }
    }

};

//取得Send to的資料(要傳進Send to)
const getDropApi = async () => {
    //空的再取得,避免多次CALL API
    if (creatorList.value.length === 0 && groupList.value.length === 0) {
        loading.value = true;
        const ajaxs = [
            ajax.get('/announcement/get-creator-list'),
            ajax.get('/announcement/get-group-list'),
        ];

        Promise.all(ajaxs).then((res) => {
            creatorList.value = res[0].data.data;
            groupList.value = res[1].data.data;
            loading.value = false;
        });
    }

};
//+++++++++++++++++++++
//mounted
//+++++++++++++++++++++
onMounted(() => {
    emitter.on('open-edit-announcement', (val) => {
        openEditing(val)
    });
});

onUnmounted(() => {
    emitter.off('open-edit-announcement', openEditing);
});

</script>
<style lang="scss">
.Announcements-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1002 !important;

    .loading.absolute {
        height: 100vh;
    }

    .ui-modal-side--container {
        height: 100%;
        overflow: hidden;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 100%;
        max-width: 1200px;
        right: 0;
        left: unset !important;
        padding: 18px;

        >div {
            height: calc(100vh - 64px);
        }

        >div>.scrollbar-content {
            margin-top: 16px;
            height: calc(100% - 32px);
            position: relative;
            display: grid;
            gap: 16px;
            grid-template-columns: 2fr 1fr;

            .content-right {
                background-color: var(--bg-02);
                padding: 16px;

                h3 {
                    font: var(--font-med-20);
                    margin-bottom: 16px;
                }
            }

            .content-left {
                p {
                    font: var(--font-med-14);
                    color: var(--text-secondary);
                    margin-bottom: 4px;
                }
            }

            .content-head-left {
                p {
                    margin-bottom: 6px;
                }
            }

            label {
                display: flex;
                align-items: center;
                font: var(--font-med-16);
                color: var(--text-primary);
                margin-bottom: 6px;
                margin-right: 6px;

                &::after {
                    content: '*';
                    color: var(--Red-30);
                    margin-left: 4px;
                    font: var(--font-med-14);
                }
            }

            .ui-checkbox {
                margin: 4px;
                font: var(--font-med-12);
                display: grid;
                gap: 4px;
                grid-template-columns: 16px 1fr;
                line-height: 18px;
                align-items: unset;

                .svg-icon {
                    min-width: 16px;
                    margin: 0;
                    margin-top: 2px;
                }
            }

            .tox-tinymce {
                height: 100%;
                min-height: 500px;
                border: solid 1px var(--black-20);
            }

        }

        header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-left {
                display: flex;
                align-items: center;
                font: var(--font-med-24);
                gap: 16px;

                svg {
                    height: 16px;
                    width: 16px;
                    cursor: pointer;
                }
            }

            .header-right {
                display: flex;
                gap: 8px;

                svg {
                    height: 14px;
                    width: 14px;
                    margin-right: 8px;
                }
            }
        }

        .el-button {
            margin: 0;

            &.click-btn {
                border: solid 1px var(--black-75);

                svg {
                    width: 16px;
                    height: 16px;
                    margin-left: 8px;
                }
            }
        }


        .sticky-btn {
            grid-column: 1 / span 2;
            position: sticky;
            padding: 16px 0 0;
            bottom: 0;
            background-color: var(--black-00);
            z-index: 3;
        }

        .content-head {
            margin-bottom: 16px;
            display: flex;
            align-items: flex-end;
            gap: 8px;

            .avatar {
                position: relative;
                width: 180px;
                height: 180px;

                p {
                    font: var(--font-med-14);
                    color: var(--text-secondary);
                    margin-bottom: 4px;
                }

                .img-box-empty {
                    width: 180px;
                    height: 180px;
                    overflow: hidden;
                    border-radius: 8px;
                    position: relative;
                    background-color: var(--black-10);
                    border: solid 1px var(--black-40);

                    .img-box-empty-content {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);

                        .svg-icon {
                            width: 42px;
                            height: 42px;
                            fill: var(--text-secondary);
                            margin: auto;
                            margin-bottom: 8px;
                        }
                    }
                }

                img {
                    width: 180px;
                    height: 180px;
                    object-fit: contain;
                    border: solid 1px var(--black-40);
                    border-radius: 8px;
                }
            }
        }

        .content-content {
            display: grid;
            gap: 8px;

            .post-to {
                display: flex;
                gap: 4px;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4px;

                .post-to-fake-input {
                    display: inline-flex;
                    flex-grow: 1;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 4px 11px;
                    background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
                    background-image: none;
                    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
                    transition: var(--el-transition-box-shadow);
                    transform: translate3d(0, 0, 0);
                    box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
                    height: (--el-input-inner-height);
                    flex-wrap: wrap;
                    gap: 4px;

                    span {
                        background-color: var(--light-neutral);
                        padding: 2px 8px;
                        border-radius: 4px;

                    }
                }
            }

            .desc {
                font-size: 12px;
                color: var(--text-secondary);
            }
        }

        .content {
            margin-bottom: 16px;

            .flex-line {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
            }

            .opt {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 4px;

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font: var(--font-med-12);
                    border: solid 1px var(--neutral);
                    height: 30px;
                    border-radius: 6px;
                    cursor: pointer;
                    transition: .3s;
                    padding: 0 10px;
                    color: var(--black-50);

                    .svg-icon {
                        width: 10px;
                        height: 10px;
                        margin-left: 4px;
                    }

                    &.pick-btn {
                        border: solid 1px var(--dark-blue);
                        color: var(--dark-blue);
                    }
                }
            }

            .pick-date-box {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 4px;

                .mx-datepicker {
                    width: 100% !important;
                }
            }
        }

        .view-announcement {
            .scrollbar-content {
                display: block;
            }

            h3 {
                font: var(--font-med-30);
            }

            .brand-box {
                display: flex;
                align-items: center;
                margin: 16px 0;
                gap: 8px;
                font: var(--font-med-16);
                color: var(--text-primary);

                img {
                    width: 28px;
                    height: 28px;
                    border-radius: 8px;
                    border: solid 1px var(--border);
                    object-fit: contain;
                }
            }

            .detail-box {
                .detail-line {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px 24px;

                    .line {
                        font: var(--font-med-14);
                        color: var(--text-primary);

                        span {
                            font-family: var(--font);
                            font-size: 12px;
                            font-weight: normal;
                        }
                    }
                }
            }

            .view-content {
                padding: 8px 0;
                $margin: 16px;
                color:var(--text-primary);
                p{
                    margin: $margin 0;
                }

                strong {
                    font-weight: 700;
                }

                h1 {
                    font: var(--font-med-26);
                    margin: $margin 0;
                }

                h2 {
                    font: var(--font-med-24);
                    margin: $margin 0;
                }

                h3 {
                    font: var(--font-med-22);
                    margin: $margin 0;
                }

                h4 {
                    font: var(--font-med-16);
                    margin: $margin 0;
                }

                h5 {
                    font: var(--font-med-12);
                    margin: $margin 0;
                }

                h6 {
                    font: var(--font-med-10);
                    margin: $margin 0;
                }

                ul {
                    padding-left: 24px;
                    margin-bottom: $margin;

                    li {
                        margin-bottom: calc($margin / 2);
                        list-style: disc;
                    }
                }

                ol {
                    padding-left: 24px;
                    margin-bottom: $margin;

                    li {
                        margin-bottom: calc($margin / 2);
                        list-style: decimal;
                    }
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: $margin;
                    border: 1px solid var(--black-10);

                    th,
                    td {
                        border: 1px solid var(--black-10);
                        padding: calc($margin / 2);
                    }

                    th {
                        background-color: var(--bg-02);
                    }
                }

                hr {
                    border: none;
                    border-top: 1px solid var(--black-20);
                    margin: $margin 0;
                }

                strong {
                    font-weight: 700;
                }

                em {
                    font-style: italic;
                }

                a {
                    color: var(--blue);
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }

                img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                }

                .video-wrap {
                    position: relative;
                    padding-bottom: 56.25%;
                    height: 0;
                    overflow: hidden;
                    margin-bottom: $margin;

                    video {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }


    .alert-dialog {
        p {
            font-size: 16px;
        }

        .btn-row {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            padding: 16px 0 0;
        }
    }

    .alert {
        font-size: 14px;
        padding: 8px 0;
        color: var(--Red-30);
    }

    .el-input {
        height: 34px;
    }

    input::placeholder {
        color: rgba(34, 47, 62, .7);
        font-family: sans-serif;
        font-size: 14px;
    }

    input::-webkit-input-placeholder {
        color: rgba(34, 47, 62, .7);
    }

    input::-moz-placeholder {
        color: rgba(34, 47, 62, .7);
    }

    input:-ms-input-placeholder {
        color: rgba(34, 47, 62, .7);
    }

    input:-moz-placeholder {
        color: rgba(34, 47, 62, .7);
    }

}
</style>