<template>
  <div class="message-item" :class="[{ 'is-self': props.item.isSelf }, 'type-' + props.item.message_type]" ref="messageItem">
      <header class="message-item-header">
        <div class="message-item-info">
          <el-image v-if="props.item.client_type === 'merchant'" :src="props.item.avatar" fit="contain" />
          <el-avatar v-else :src="props.item.avatar" fit="cover"/>
          <span v-if="props.item.isSelf && props.item.client_type !== 'merchant'">You</span>
          <span v-else>{{ props.item.name }}</span>
        </div>
        <time class="message-item-date">
            <span>{{ datetime }}</span> 
        </time>
      </header>
      <div class="message-item-content-wrap">
        <div class="welcome-card" v-if="props.item.message_type === 'announcement-message' && props.item.chatroom_announcement_id">
          <div class="card announcement-card" @click="emit('open-announcement', props.item.chatroom_announcement_id)" :class="{ 'have-image': props.item.chatroom_announcement.thumbnail_url }">
            <el-image v-if="props.item.chatroom_announcement.thumbnail_url" :src="props.item.chatroom_announcement.thumbnail_url" />
            <h2 v-if="props.item.chatroom_announcement.title" v-html="props.item.chatroom_announcement.title"></h2>
            <p v-if="props.item.chatroom_announcement.description" v-html="desc(props.item.chatroom_announcement.description)"></p>
            <time>{{ moment(props.item.chatroom_announcement.updated_at).format('MMM DD, YYYY') }}</time>
          </div>
        </div>
        <div v-else-if="messageType === 'text'" class="message-item-content" v-html="formatMessage(message)">
        </div>
        <div v-else-if="messageType === 'loading'" class="message-item-content">
          <el-button type="text" loading></el-button>
        </div>
        <div class="welcome-card" v-else-if="messageType === 'card'" v-html="message"></div>
        <div class="error-info" v-if="props.item.isNew && isError">
          <svg-icon name="retry" />
          <span @click="emit('retry-message', props.item.message_id)">Retry</span>
        </div>
      </div>
      <footer class="message-item-footer" v-if="props.type === 'merchant'">
        <p v-if="props.item.client_type === 'merchant'" class="send-by">
          {{ 
            props.item.message_type === 'announcement-message' ? 'Announcement (automated)' :
            props.item.message_type === 'welcome-message' ? 'Welcome message (automated)' :
            props.item.current_merchant_portal_user_name ? 'Sent by ' + props.item.current_merchant_portal_user_name : ''
          }}
        </p>
      </footer>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import moment from 'moment';
import { ElImage, ElAvatar, ElButton } from 'element-plus';
import localforage from 'localforage';

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'chatroom',
  version: 1.0,
  storeName: 'message-links',
  description: 'Anchor message link'
});

// const timer = ref(null);
const message = ref('');
const messageType = ref('');
const isError = ref(false);
const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  item: {
    type: Object,
    default: () => ({})
  },
  ajax: {
    type: Function,
    required: true
  }
});


const emit = defineEmits(['retry-message', 'mark-as-read', 'open-announcement']);
const ajax = props.ajax;
const messageItem = ref(null);
let observer = null;

const formatMessage = (message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  
  return message.replace(urlRegex, (url) => {
    return `<a href="${url}" class="link-item" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};

const showDate = (date) => {
  // check if date is today
  if (moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) 
    return moment(date).format('hh:mma');
  else 
    return moment(date).format('MM/DD/YYYY hh:mma');
  
};

const local = showDate(moment.utc(props.item.created_at).local());
const datetime = ref(local);

const desc = (text) => {
  // remove all html tsgs and return only text
  const regex = /(<([^>]+)>)/ig;
  return text.replace(regex, '');
};

const getOgMetaFromUrl = async (url) => {
  const response = await ajax.get(`/chatroom/get-link-preview?link=${url}&message_id=${props.item.message_id}`);
  return response.data.data;
};

const handleLinkToHtml = (res, url) => {
  if (res.title === '' && res.description === '') { 
    return '';
  }

  return `
    <a class="card" href="${url}" target="_blank" rel="noopener noreferrer">
      ${res.image ? `<figure><img src="${res.image}" /></figure>` : ''}
      ${res.title ? `<h2>${res.title}</h2>` : ''}
      ${res.description ? `<p>${res.description}</p>` : ''}
    </a>
  `;
};

const handleIntersection = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting && !props.item.isSelf && props.item.message_status !== 100) {
      // log('yellow', 'Message item is in view', props.item.message_id);
      emit('mark-as-read', [props.item.message_id]);
      // props.item.message_status = 100;
    }
  });
};

onMounted(() => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  messageType.value = 'loading';

  observer = new IntersectionObserver(handleIntersection, {
    root: null, // use the viewport as the root
    threshold: 0.1 // trigger when 10% of the element is in view
  });

  if (messageItem.value) {
    observer.observe(messageItem.value);
  }

  if (props.item.message_text.match(urlRegex)) {
    const key = 'link_data_' + props.item.message_id;
    const urls = props.item.message_text.match(urlRegex);
    const text = props.item.message_text.replace(urlRegex, '');

    localforage.getItem(key).then((val) => {
      if (val === null) {
        const ajaxs = [];
        let html = '';

        urls.forEach((url) => {
          ajaxs.push(getOgMetaFromUrl(url));
        });

        Promise.all(ajaxs).then((res) => {
          res.forEach((item, index) => {
            html = html + handleLinkToHtml(item, urls[index]);
          });
          
          if (html === '') {
            messageType.value = 'text';
            message.value = `<p>${props.item.message_text}</p>`;

          } else {
            messageType.value = 'card';

            if (text) {
              message.value = `<p>${text}</p>` + html;
            } else {
              message.value = html;
            }
          }

          // log('html', html);
          localforage.setItem(key, message.value);
        }).catch((err) => {
          messageType.value = 'text';
          message.value = `<p>${props.item.message_text}</p>`;
        });
      } else {
        if (val.indexOf('"card"') === -1) {
          messageType.value = 'text';
          message.value = `<p>${props.item.message_text}</p>`;
        } else {
          messageType.value = 'card';
          message.value = val;
        }
      }
    });


  } else {
    messageType.value = 'text';
    message.value = `<p>${props.item.message_text}</p>`;
  }

  // timer.value = setInterval(() => {
  //   // datetime.value = FormatDateDaysAgo(local);
  //   datetime.value = local;

  //   if (props.item.isSelf && props.item.isNew === true) {
  //     isError.value = true;
  //     log('red', 'Message post error', props.item.message_id);
  //   }

  // }, 5000);
});

onUnmounted(() => {
  if (observer && messageItem.value) {
    observer.unobserve(messageItem.value);
  }
  // clearInterval(timer.value);
});
</script>

<style lang="scss">
.message-item {
  margin-bottom: 16px;
  max-width: 460px;
  width: 100%;
  clear: both;
  justify-content: flex-end;

  .#{$prefix}-image {
    background-color: white;
  }

  .message-item-header {
    display: flex;
    align-items: center;

    .message-item-info {
      display: flex;
      align-items: center;
      flex: 1;

      .#{$prefix}-avatar,
      .#{$prefix}-image {
        width: 32px;
        height: 32px;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .#{$prefix}-image {
        border-radius: var(--radius);
        overflow: hidden;
      }

      span {
        font: var(--medium-14);
        color: var(--Text-primary);
      }
    }

    .message-item-date {
        font: var(--book-10);
        color: var(--Text-default);
    }
  }

  .welcome-card {
    margin-left: 40px;
    padding: 10px;
    background-color: var(--Bg-light);
    border-radius: var(--radius);
    box-shadow: var(--bot-2dp);

    & > p,
    & > label {
      color: var(--Text-primary);
      margin-bottom: 10px;
      display: block;
    }


    .card {
      padding: 12px;
      border: 1px solid var(--Border-default);
      border-radius: var(--radius);
      display: block;
      color: var(--Text-primary);
      background-color: var(--Bg-light);
      overflow: hidden;
      cursor: pointer;

      &:hover {
        border-color: var(--Neu-40);
      }

      
      & + .card, 
      & + .card + .card,
      & + .card + .card + .card,
      & + .card + .card + .card + .card {
        margin-top: 8px;
      }

      &:hover {
        text-decoration: none;
        // border-color: var(--Border-primary);
      }

      figure {
        overflow: hidden;
        margin: -12px -12px 10px;
        position: relative;

        &::before {
          content: '';
          display: block;
          padding-top: 56.25%;
        }

        img {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          height: 100%;
        }
      }

      h2 {
        font: var(--medium-14);
        color: var(--Text-primary);
        margin-bottom: 6px;
      }

      p {
        color: var(--Text-secondary);
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      time {
        font: var(--medium-12);
        color: var(--Text-default);
      }
    }

    .announcement-card {
      position: relative;
      display: grid;
      grid-template-rows: auto 1fr auto;
      
      &.have-image {
        padding-left: 124px;
        height: 124px;
        overflow: hidden;
      }

      .#{$prefix}-image {
        height: 100px;
        width: 100px;
        left: 12px;
        top: 12px;
        position: absolute;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      h2,
      p {
        // max 2 lines
        max-height: 38px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
      }

      h2 {
        max-height: 32px;
      }
      
      time {
        font: var(--medium-12);
        color: var(--Text-default);
      }
    }
  }

  .message-item-content {
    padding: 10px;
    background-color: var(--Bg-light);
    border-radius: var(--radius);
    text-align: left;
    box-shadow: var(--bot-2dp);
    margin-left: 40px;
    overflow: hidden;


    .link-item {
      word-wrap: break-word;
    }

    p {
      color: var(--Text-primary);
    }
  }
  .message-item-footer {

    .send-by {
      margin-top: 8px;
      font: var(--book-10);
      color: var(--Text-default);
      padding-left: 40px;
    }
  }

  &.is-self .message-item-footer {
    .send-by {
      padding-right: 40px;
      text-align: right;
    }
  }

  .ui-loading-mask {
    background-color: transparent;

    .circular {
      width: 24px;
      height: 24px;
      left: 2px;
      top: 10px;
      position: relative;
    }
  }

  .el-button.is-loading {
    padding: 0 8px;

    &:before {
      background-color: transparent;
    }

    .el-icon,
    .el-icon svg {
      height: 18px;
      width: 18px;
    }
  }

  .error-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--Text-primary);
    cursor: pointer;
    padding-right: 40px;
    position: relative;

    svg {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 8px;
      top: -22px;
      color: var(--Red-30);
    }

    span {
      font-size: 12px;
      display: inline-block;
      padding-top: 4px;
      cursor: pointer;
      color: var(--Text-link);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.is-self {
    align-self: flex-end;

    .message-item-header {
      .#{$prefix}-image,
      .#{$prefix}-avatar {
        order: 2;
        margin-right: 0;
        margin-left: 8px;
      }

      .message-item-info {
        order: 2;
        flex: unset;
      }

      .message-item-date {
        order: 1;
        flex: 1;
      }
    }

    .welcome-card {
      margin-left: 0;
      margin-right: 40px;
      background-color: var(--Neu-20);
    }

    .message-item-content {
      margin-left: 0;
      margin-right: 40px;
      background-color: var(--Neu-20);
    }
  }
  
}
</style>